<template>
  <div>
    <el-row class="m-0" :gutter="20">
      <el-col v-if="userCanEdit" :span="3">
        <el-button @click="handleCreate" type="text" icon="el-icon-circle-plus-outline" >Добавить</el-button>
      </el-col>
      <el-col :span="3">
      <el-button @click="handleDownLoad" type="text"
                  :loading="loading"
                   icon="el-icon-download">Выгрузить
        </el-button>
      </el-col>
    <el-col :span="12">
      <pagination
        style="text-align: center"
        :total-count="totalCount"
        :page-size.sync="pageSize"
        :current-page.sync="pageNumber"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </el-col>
    </el-row>
    <div>
      <el-table
        ref="table"
        v-loading="loading"
        style="width: 100%"
        :height="tableHeight"
        :data="secondEquipmentTypes"
        :default-sort = "{prop: 'name', order: 'ascending'}"
        @sort-change="handleSortChange"
      >
        <el-table-column v-if="userCanEdit" label="" width="50">
          <template slot-scope="scope">
              <el-button type="text" icon="el-icon-edit"
                        @click="handleEdit(scope.$index, scope.row)"></el-button>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="Наименование"
          sortable="custom"
          width="300">
        </el-table-column>
        <el-table-column
          prop="startDate"
          label="Дата начала эксплуатации"
          sortable="custom"
          width="250"
          :formatter="dateTimeRenderer">
        </el-table-column>
        <el-table-column
          prop="endDate"
          label="Дата отключения"
          sortable="custom"
          width="200"
          :formatter="dateTimeRenderer">
        </el-table-column>
        <el-table-column
          prop="energyObjects"
          label="Энергообъекты"
          min-width="20">
          <template slot-scope="scope">
            {{scope.row.energyObjectsStr}}
          </template>
        </el-table-column>
        <el-table-column v-if="userCanEdit" label="" width="50">
            <template slot-scope="scope">
                  <el-button type="text" icon="el-icon-delete"
                            @click="handleDelete(scope.$index, scope.row)"></el-button>
            </template>
          </el-table-column>
      </el-table>
    </div>
    <second-equipment-type-modal @on-update="getSecondEquipmentTypes()" @on-create="getSecondEquipmentTypes()" v-model="dialogVisible" :secondEquipmentType="dialogSecondEquipmentType"></second-equipment-type-modal>
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';
import { mapGetters } from 'vuex';
import secondEquipmentTypesApi from '@/api/nsi/secondEquipmentTypes';
import reportsApi from '@/api/reports';
import io from '@/utilities/fileIO';
import pagination from '@/components/Ui/Pagination';
import formatters from '@/mixins/formatters.js';
import tableResize from '@/mixins/tableResize.js';
import dateHelpers from '@/mixins/dateHelpers';
import xlsUpDownHelper from '@/mixins/xlsUpDownHelper';
import SecondEquipmentTypeModal from './SecondEquipmentTypeModal';

export default {
  name: 'SecondEquipmentTypesTable',
  props: {
    filterModel: { type: Object, default: () => {} },
    filterActive: { type: Boolean, default: () => true },
  },
  mixins: [formatters, tableResize, dateHelpers, xlsUpDownHelper],
  components: { pagination, SecondEquipmentTypeModal },
  data() {
    return {
      loading: false,
      secondEquipmentTypes: [],
      pageNumber: 1,
      pageSize: 50,
      totalCount: 0,
      sortField: 'name',
      sortDesc: false,
      dialogVisible: false,
      dialogSecondEquipmentType: {},
    };
  },
  computed: {
    ...mapGetters('identity', ['userHasRole']),
    userCanEdit() {
      return this.userHasRole('NsiLocalEdit,NsiFullAccess');
    },
  },
  watch: {
    filterModel: {
      handler() {
        this.getSecondEquipmentTypes();
      },
      deep: true
    },
  },
  async mounted() {
    this.$nextTick(() => {
      this.tableHeight = this.getTableHeight();
    });
    await this.getSecondEquipmentTypes();
    this.tableHeight = this.getTableHeight();
  },
  methods: {
    async handleDownLoad() {
      this.loading = true;
      const params = {
        nameFilter: this.filterModel.nameFilter,
        energyObjectNameFilter: this.filterModel.energyObjectNameFilter,
        secondEquipmentNameFilter: this.filterModel.secondEquipmentNameFilter
      }; 
      const res = await reportsApi.createSecondEquipmentTypesReport(params);
      if (res.data.exceptionDetails) {
        this.$alert(`При формировании отчета произошла ошибка: ${res.data.exceptionDetails}`, 'Ошибка');
      } else {
        io.downloadFile(res.data.content, res.data.contentType, res.data.fileName);
      }
      this.loading = false;
    },
    async getSecondEquipmentTypes() {
      this.loading = true;
      const params = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        nameFilter: this.filterModel.nameFilter,
        sortField: this.sortField,
        sortDesc: this.sortDesc,
        energyObjectNameFilter: this.filterModel.energyObjectNameFilter,
        secondEquipmentNameFilter: this.filterModel.secondEquipmentNameFilter
      };
      const res = await secondEquipmentTypesApi.getSecondEquipmentTypesWithPagination(params);
      if (res.data) {
        this.secondEquipmentTypes = res.data.items;
        if (res.data.items) {
          res.data.items.forEach((el) => {
            if (el.energyObjects) {
              el.energyObjectsStr = el.energyObjects.map((el2) => el2.value).join('; ');
            }
          });
        }
        this.totalCount = res.data.totalCount;
      }
      this.loading = false;
    },
    async handleSizeChange() {
      this.pageNumber = 1;
      await this.getSecondEquipmentTypes();
    },
    async handleCurrentChange() {
      await this.getSecondEquipmentTypes();
    },
    async handleSortChange(event) {
        this.sortField = event.prop;
        this.sortDesc = event.order === 'descending';
        await this.getSecondEquipmentTypes();
    },
    async handleEdit(index, secondEquipmentType) {
      const res = await secondEquipmentTypesApi.getSecondEquipmentTypeItem(secondEquipmentType.id);
      this.dialogSecondEquipmentType = this.getEditable(res.data);
      this.dialogVisible = true;
    },
    // eslint-disable-next-line no-unused-vars
    handleCreate(index, secondEquipmentType) {
      this.dialogSecondEquipmentType = {
        id: null, name: null, startDate: null, endDate: null
      };
      this.dialogVisible = true;
    },
    handleDelete(index, secondEquipmentType) {
      this.$confirm('Вы действительно хотите удалить запись?', 'Удаление записи!', {
        confirmButtonText: 'ОК',
        cancelButtonText: 'Отмена',
        type: 'warning',
      }).then(async () => {
        this.loading = true;
        const res = await secondEquipmentTypesApi.deleteSecondEquipmentType(secondEquipmentType.id);
          if (!res.data.succeeded) {
            this.$alert(res.data.resultMessage, 'Ошибка', {
              confirmButtonText: 'OK',
              type: 'warning',
            });
          } else {
            await this.getSecondEquipmentTypes();
          }
        this.loading = false;
      });
    },
    getEditable(secondEquipmentType) {
      const result = cloneDeep(secondEquipmentType);
      result.startDate = this.dateGetter(result.startDate);
      result.endDate = this.dateGetter(result.endDate);
      return result;
    },
  }
};
</script>

<style scoped>

</style>
