<template>
  <el-dialog
    v-if="dialogVisible"
    :visible.sync="dialogVisible"
    width="65%"
    top="2rem"
    :close-on-click-modal="false"
  >
    <div slot="title">
      Комплексы РЗА
      <el-button class="back_action_panel--first_element custom-button " @click="handleSave" type="text">
          <SaveIcon /> Сохранить
      </el-button>
   </div>
    <el-form :rules="rules" v-loading="loading" ref="secondEquipmentTypeForm" :model="secondEquipmentType" label-width="14rem">
      <el-form-item prop="name" label="Наименование:">
        <el-input v-model="secondEquipmentType.name"></el-input>
      </el-form-item>
      <el-form-item prop="startDate" label="Дата начала эксплуатации:">
        <el-date-picker
          type="date"
          format="dd.MM.yyyy"
          v-model="secondEquipmentType.startDate"
          v-mask="'##.##.####'"
          placeholder="дд.мм.гггг"
          style="width: 40%;"
          :picker-options="datePickerOptions">
        </el-date-picker>
      </el-form-item>
      <el-form-item prop="endDate" label="Дата отключения:">
        <el-date-picker
          type="date"
          format="dd.MM.yyyy"
          v-mask="'##.##.####'"
          placeholder="дд.мм.гггг"
          v-model="secondEquipmentType.endDate"
          style="width: 40%;"
          :picker-options="datePickerOptions">
        </el-date-picker>
      </el-form-item>
      <el-form-item prop="secondEquipmentIds " label="Функции РЗА:">
        <div class="selected-tags">
          <el-tag
            v-for="tag in secondEquipmentType.secondEquipmentsInit"
            :key="tag.id"
            size="small"
            class="mr-1 mb-1">
            {{tag.value}}
          </el-tag>
          <el-button type="primary" size="small" @click="handleSecondEquipmentsSelect">
            Выбрать
          </el-button>
        </div>
      </el-form-item>
    </el-form>
    <second-equipments-tree-modal
      v-if="secondEquipmentsTreeModalVisible"
      v-model="secondEquipmentsTreeModalVisible"
      :data="secondEquipmentType.secondEquipmentsInit"
      @on-update="secondEquipmentsUpdate"
    />
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import cloneDeep from 'lodash.clonedeep';
import secondEquipmentTypesApi from '@/api/nsi/secondEquipmentTypes';
import dateHelpers from '@/mixins/dateHelpers';
import SaveIcon from '@/assets/icons/save.svg';
import secondEquipmentsTreeModal from '@/views/Global/SecondEquipmentsSelect/SecondEquimpentsTreeModal';

export default {
  name: 'SecondEquipmentTypeModal',
  props: ['value', 'secondEquipmentType'],
  components: { secondEquipmentsTreeModal, SaveIcon },
  mixins: [dateHelpers],
  computed: {
    ...mapGetters('identity', ['userTimeZone']),
    dialogVisible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  data() {
    return {
      loading: false,
      rules: {
        name: [
          { required: true, message: 'Поле обязательно для заполнения', trigger: 'blur' },
          { whitespace: true, message: 'Поле обязательно для заполнения', trigger: 'blur' }
        ],
      },
      parents: {},
      secondEquipmentsTreeModalVisible: false,
    };
  },
  methods: {
    handleClose() {
      // console.log(this.secondEquipmentType);
      this.dialogVisible = false;
    },
    async addSecondEquipmentType(secondEquipmentType) {
      await secondEquipmentTypesApi.addSecondEquipmentType(secondEquipmentType);
      this.$emit('on-create');
    },
    async updateSecondEquipmentType(secondEquipmentType) {
      await secondEquipmentTypesApi.updateSecondEquipmentType(secondEquipmentType);
      this.$emit('on-update');
    },
    async validateForm() {
      const valid = await this.$refs.secondEquipmentTypeForm.validate();
      return valid;
    },
    secondEquipmentsUpdate(secondEquipmentsFromModal) {
      this.secondEquipmentType.secondEquipmentsInit = secondEquipmentsFromModal.map((t) => ({ value: t.name, baseId: t.id, ...t }));
      this.secondEquipmentType.secondEquipmentIds = secondEquipmentsFromModal.map((t) => t.baseId);
    },
    getSaveable() {
      const result = cloneDeep(this.secondEquipmentType);
      result.secondEquipmentsInit = [];
      result.startDate = this.dateSetter(result.startDate);
      result.endDate = this.dateSetter(result.endDate);
      return result;
    },
    async handleSave() {
      const valid = await this.validateForm();
      if (!valid) return;

      const request = this.secondEquipmentType.id ? this.updateSecondEquipmentType : this.addSecondEquipmentType;
      this.loading = true;

      // console.log('set', this.secondEquipmentType);
      // console.log('save', this.getSaveable());
      await request(this.getSaveable());
      this.dialogVisible = false;
      this.loading = false;
    },
    handleSecondEquipmentsSelect() {
      this.secondEquipmentsTreeModalVisible = true;
    }
  },
};
</script>

<style scoped lang="scss">
.el-tag {
  white-space: pre-wrap;
  height: auto;
}
.selected-tags{
  line-height: normal;
}
</style>
