import axios from 'axios';

export default {
  getSecondEquipmentTypesWithPagination(params) {
    return axios.post('/api/SecondEquipmentTypes/GetSecondEquipmentTypesWithPagination', params);
  },
  getSecondEquipmentTypeItem(id) {
    return axios.get('/api/SecondEquipmentTypes/GetSecondEquipmentTypeItem', {
      params: {
        id
      }
    });
  },
  addSecondEquipmentType: (secondEquipmentType) => axios.post('/api/SecondEquipmentTypes', secondEquipmentType),
  updateSecondEquipmentType: (secondEquipmentType) => axios.put(`/api/SecondEquipmentTypes/${secondEquipmentType.id}`, secondEquipmentType),
  deleteSecondEquipmentType: (id) => axios.delete(`/api/SecondEquipmentTypes/${id}`), 
};
