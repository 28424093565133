<template>
  <div>
    <second-equipment-types-filter @active-change="filterActive = $event" :filter-model="filterModel" />
    <second-equipment-types-table :filter-active="filterActive" :filter-model="filterModel" />
  </div>
</template>

<script>
import SecondEquipmentTypesFilter from '@/views/Nsi/SecondEquipmentTypes/SecondEquipmentTypesFilter';
import SecondEquipmentTypesTable from '@/views/Nsi/SecondEquipmentTypes/SecondEquipmentTypesTable';

export default {
  name: 'SecondEquipmentTypes',
  components: { SecondEquipmentTypesTable, SecondEquipmentTypesFilter },
  data() {
    return {
      filterModel: {
        nameFilter: '',
        energyObjectNameFilter: [],
        secondEquipmentNameFilter: []
      },
      filterActive: true
    };
  }
};
</script>

<style scoped>

</style>
